<template>
  <div class="create-reading-modal">
    <a-alert :message="error" type="warning" v-if="error" show-icon style="margin-bottom: 20px" />
    <a-radio-group v-model:value="formState.type">
      <a-radio-button value="real">
        {{ i18n.t('accounts.trading.real', 2) }}
      </a-radio-button>
      <a-radio-button value="demo">
        {{ i18n.t('accounts.trading.demo', 2) }}
      </a-radio-button>
    </a-radio-group>
    <a-form :model="formState" @finish="createTradingAccount" ref="createTrading" :rules="rules" name="createTrading">
      <a-form-item ref="name" name="name" :label="i18n.t('start.trading-name')">
        <a-input v-model:value="formState.name" size="large" type="text" />
      </a-form-item>
      <a-form-item ref="leverage" name="leverage" :label="i18n.t('start.trading-leverage')">
        <a-select v-model:value="formState.leverage" size="large" type="text" v-if="leverages">
          <a-select-option v-for="leverage of leverages[formState.type]" :value="leverage.value" :key="leverage.value">
            {{ leverage.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item ref="amount" name="amount" :label="i18n.t('start.trading-amount')">
        <a-input
          v-model:value="formState.amount"
          :min="formState.type == 'demo' ? 1000 : 10"
          size="large"
          type="number"
          :style="`width:${windowSize > 850 ? 300 : 272}px`"
        >
          <template #addonAfter>
            <p>USDT</p>
          </template>
        </a-input>
      </a-form-item>
      <div class="create-account__buttons">
        <sdButton size="large" :disabled="isLoading" type="success" htmlType="submit" raised style="width: 100%">
          {{ i18n.t('start.create') }}
        </sdButton>
      </div>
    </a-form>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const CreateTradingModal = {
  props: { currentTradingAcc: String },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    let validationCount = 0;

    const createTrading = ref();

    const formState = ref({
      leverage: 1,
      type: props.currentTradingAcc,
      name: '',
      amount: '',
    });
    const errors = computed(() => state.tradingAccounts.error);

    const errorValidator = (check) => {
      let isError = null;

      if (errors.value && Object.keys(errors.value).length > validationCount) {
        Object.keys(errors.value).forEach((err) => {
          if (err == check.field) {
            isError = Promise.reject(
              i18n.t(`start.trading-${err}`) + ' ' + errors.value[err].join(i18n.t('messages.and')),
            );
          }
        });
        if (isError) {
          validationCount++;
          return isError;
        }
      }
      return Promise.resolve();
    };

    const rules = reactive({
      name: [
        {
          validator: errorValidator,
        },
      ],
      amount: [
        {
          validator: errorValidator,
        },
      ],
    });

    const windowSize = computed(() => window.innerWidth);
    const tradingAccountsPage = computed(() => props.currentTradingAcc);
    const isLoading = computed(() => state.tradingAccounts.createLoading);
    const leverages = computed(() => state.tradingAccounts.leverages);

    const error = computed(() => {
      if (state.tradingAccounts.error) {
        if (typeof state.tradingAccounts.error === 'object') {
          if (typeof state.tradingAccounts.error.errors === 'string') {
            return state.tradingAccounts.error.errors;
          } else {
            return Object.entries(state.tradingAccounts.error).map((value) => {
              if (value[0] != 'amount' && value[0] != 'leverage' && value[0] != 'name') {
                return value[1][0];
              }
            });
          }
        } else {
          return state.tradingAccounts.error == 500 ? i18n.t('error.500') : state.tradingAccounts.error;
        }
      }
      return false;
    });

    const createTradingAccount = async () => {
      validationCount = 0;
      await dispatch('createTradingAccount', formState.value);
      createTrading.value.validate();
      if (!error.value) {
        emit('closeModal', formState.value.type);
      }
      if (formState.value.type === 'real') {
        dispatch('axiosAccountsGetData');
      }
    };

    onMounted(() => {
      if (!leverages.value) {
        dispatch('getLeverages');
        formState.value.type = props.currentTradingAcc;
      }
    });
    watchEffect(() => {
      formState.value.type = props.currentTradingAcc;
    });

    return {
      i18n,
      formState,
      leverages,
      tradingAccountsPage,
      windowSize,
      error,
      isLoading,
      rules,
      createTrading,
      errorValidator,
      createTradingAccount,
    };
  },
};
export default CreateTradingModal;
</script>

<style scoped lang="scss">
.create-reading-modal {
  .ant-radio-group {
    margin-bottom: 20px;
  }
}
</style>
